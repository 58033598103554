
var nav = responsiveNav(".nav-main", {
	open: function(){
		$("body").addClass('noscroll');
	}, // Function: Open callback
	close: function(){
		$("body").removeClass('noscroll');
	} // Function: Close callback
});

var itemsGallery = [];

function openPhotoSwipeEditor(event) {
	
	
	$('.editor-text a.zoom').each(function(index) {

		item = {
			src: $(this).attr('href'),
			w: $(this).attr('data-width'),
			h: $(this).attr('data-height')
        };

		itemsGallery.push(item);

	});

	options = {
	    closeOnScroll: false,
	    shareEl: false,
	    counterEl: false,
	    index: $(event.currentTarget).index()
	};

	gallery = new PhotoSwipe( $(".pswp")[0], PhotoSwipeUI_Default, itemsGallery, options);
	gallery.init();	

}

function cctv(){

    var player;
    function initPlayer(videoid) {
        console.log(videoid);
        player = new YT.Player('player', {
            height: '360',
            width: '640',
            videoId: videoid,
            playerVars: {
                'autoplay': 1,
                'showinfo': 0,
                'modestbranding': 1,
                'rel': 0,
                'enablejsapi': 1
            }
        });
    }

    $('.youtube-video').each(function(index){
        var poster = "https://img.youtube.com/vi/"+ $(this).attr('data-video-id') +"/maxresdefault.jpg";
        if( index == 0 ){
            $('.youtube-player').append('<img src="'+poster+'">');
        }
        $(this).append('<img src="'+poster+'">');
    });

    $('.youtube-video').on('click', function(index){
        var videoid = $(this).data('video-id');
        $('.youtube-video').removeClass('playing');
        $(this).addClass('playing');
        if ( $('.youtube-player').hasClass('playing') ) {
            player.loadVideoById(videoid);
        } else {
            initPlayer(videoid);
            $('.youtube-player').addClass('playing');
        }
    });

}

// jQuery stuff
$(document).ready(function() {

   cctv();

	var video = $("#video-player");

	function onResizeWindow() {
		resizeVideo(video[0]);
	}

	function onLoadMetaData(e) {
		resizeVideo(e.target);
	}

	function resizeVideo(videoObject) {
		var percentWidth = videoObject.clientWidth * 100 / videoObject.videoWidth;
		var videoHeight = videoObject.videoHeight * percentWidth / 100;
		video.height(videoHeight);
	}
	
	if( video.length > 0 ){
		var windowObj = $(window);

		video.on("loadedmetadata", onLoadMetaData);
		windowObj.resize(onResizeWindow);
	}

	// Supprime le délai de 300ms lorsqu'une ancre est tappée sur un appareil surpportant le touch
	FastClick.attach(document.body);
	
	// Initialisation de la détection de touch depuis le JS
	isTouch = Modernizr.touch;

/*
########    #######   ########   ##     ##  
##         ##     ##  ##     ##  ###   ###  
##         ##     ##  ##     ##  #### ####  
######     ##     ##  ########   ## ### ##  
##         ##     ##  ##   ##    ##     ##  
##         ##     ##  ##    ##   ##     ##  
##          #######   ##     ##  ##     ##  
*/

	// Fix pour les navigateurs qui ne supportent pas placeholder
	$('input, textarea').placeholder();
	
	// action sur les formulaires
	$("form").validationEngine({
		// scroll: false,
		binded: false,
		promptPosition: 'centerRight:-145,-3',
		autoPositionUpdate: true,
		showArrow: false,
		maxErrorsPerField: 1
	});

	// Formulaire de contact
	$("#fContact").submit(function(){		
		if($("#fContact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var div  = $(".form-return").empty().html('<p>envoi en cours...</p>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : "/contact/",
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});

	$('.harmonize').matchHeight();

/*
########    #######   ########    #######   ########      ###     ##     ##     ###     
##         ##     ##     ##      ##     ##  ##     ##    ## ##    ###   ###    ## ##    
##         ##     ##     ##      ##     ##  ##     ##   ##   ##   #### ####   ##   ##   
######     ##     ##     ##      ##     ##  ########   ##     ##  ## ### ##  ##     ##  
##         ##     ##     ##      ##     ##  ##   ##    #########  ##     ##  #########  
##         ##     ##     ##      ##     ##  ##    ##   ##     ##  ##     ##  ##     ##  
##          #######      ##       #######   ##     ##  ##     ##  ##     ##  ##     ##  
*/

	// custom nav fotorama
	var $fotoramaDiv = $('.fotorama').fotorama();
	var fotorama = $fotoramaDiv.data('fotorama');
	
	$(".fotorama-custom-nav a").click(function(e){
		e.preventDefault();
		fotorama.show( $(this).parent().index() );

	});

	$('.fotorama').on('fotorama:show', function (e, foto, extra) {
		$(".fotorama-custom-nav a").removeClass('current');
		$('.fotorama-custom-nav li:eq('+foto.activeIndex+') a').addClass("current");
	});

/*
##     ##  ########   ##    ##   ##     ##  
###   ###  ##         ###   ##   ##     ##  
#### ####  ##         ####  ##   ##     ##  
## ### ##  ######     ## ## ##   ##     ##  
##     ##  ##         ##  ####   ##     ##  
##     ##  ##         ##   ###   ##     ##  
##     ##  ########   ##    ##    #######   
*/

	var waypoint = new Waypoint({
		element: $('.container'),
		handler: function(direction) {
			if( direction == "down" ){
				$(".header").addClass('on-top');
			}
		}
	});


	// Apparition / disparition menu scroll up / down
	if( !isTouch ){
		var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel"; //FF doesn't recognize mousewheel as of FF3.x
		$('body').bind(mousewheelevt, function(e){

		    var evt = window.event || e; //equalize event object     
		    evt = evt.originalEvent ? evt.originalEvent : evt; //convert to originalEvent if possible               
		    var delta = evt.detail ? evt.detail*(-40) : evt.wheelDelta; //check for detail first, because it is used by Opera and FF

		    if(delta >= 0) {
		        $('.header').addClass('on-top');
		    }else{
		        $('.header').removeClass('on-top');
		    }

		});
	}

	if( isTouch ){
		$(window).swipe({
			swipeUp: function(){
				$('.header').removeClass('isFixed');
				nav.close();
			}, swipeDown: function(){
				$('.header').addClass('isFixed');			
			},
			allowPageScroll: "vertical",
			threshold: 0
		});
	}

	$(".go-down").click(function(e){
		e.preventDefault();
		var page = $(this).attr('href'); // Page cible
		var speed = 750; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
		return false;
	});

/*
##     ##     ###     ########    ######    
###   ###    ## ##    ##     ##  ##    ##   
#### ####   ##   ##   ##     ##  ##         
## ### ##  ##     ##  ########    ######    
##     ##  #########  ##               ##   
##     ##  ##     ##  ##         ##    ##   
##     ##  ##     ##  ##          ######    
*/

	if( $("#map").length > 0 ){
		$("#map").gmapsPlugin({
			lat:          45.7326864,
			lng:          4.99855649999995,
			zoom:         14,
			markerImage:  '/images/marker.png',
			styles:[{"stylers":[{"saturation":-100},{"gamma":1}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"on"},{"saturation":50},{"gamma":0},{"hue":"#50a5d1"}]},{"featureType":"administrative.neighborhood","elementType":"labels.text.fill","stylers":[{"color":"#333333"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"weight":0.5},{"color":"#333333"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"gamma":1},{"saturation":50}]}]
		});
	}

	$('.editor-text a.zoom').click(function(e){
		e.preventDefault();
	}).click(openPhotoSwipeEditor);


});

/*
 ######       ###     ##         ########   ########   ####  ########   
##    ##     ## ##    ##         ##         ##     ##   ##   ##         
##          ##   ##   ##         ##         ##     ##   ##   ##         
##   ####  ##     ##  ##         ######     ########    ##   ######     
##    ##   #########  ##         ##         ##   ##     ##   ##         
##    ##   ##     ##  ##         ##         ##    ##    ##   ##         
 ######    ##     ##  ########   ########   ##     ##  ####  ########   
*/

var scrolling = false;
var slider    = false;

(function ($){
	var scrolling = false;
		slider    = false;
		$tiles    = $('#tiles');
		$handler  = $('li', $tiles);
		$thumbs   = $('img', $handler);
		$main     = $('.wrapper-gallery');
		$window   = $(window);
		$document = $(document);
		options = {
			resizeDelay: 20,
			autoResize: true, 
			container: $main,
			offset: 20
		};

	/**
	 * Ajoute les photos dynamiquement
	 */
	var loadPhotos = function(event){
		var debut     = $handler.length;
		var nb_photos = 10;

		if( $(event.currentTarget).hasClass('gallery-btn--all') )
			nb_photos = 1000;

		event.preventDefault();
		
		// var url   = $(this).attr('href');
		$(".spinner").show();

		$.ajax({
			url: window.location.href,
			type: 'post',
			data: {
				ajax:      true,
				debut:     debut,
				nb_photos: nb_photos
			},
			success: function(retour) {

				if(retour !== '') {

					$tiles.append(retour);
					applyLayout();

				}
			}
		});
	}

	/**
	 * Lance PhotoSwipe Gallery
	 */
	function openPhotoSwipe(event) {
		var $thumb = $(event.currentTarget),
			items = [],
			item,
			options,
			gallery;

		options = {
		    closeOnScroll: false,
		    shareEl:       false,
		    counterEl:     true,
		    index:         $thumb.index()
		};

		if( $tiles.data('load') == "all" ){
		
			$.ajax({
				url: window.location.href,
				type: 'post',
				dataType: 'json',
				data: {
					all: true
				},
				success: function(retour) {
					$.each(retour.items, function(i,photo){
						item = {
							src: photo.src,
							w:   photo.w,
							h:   photo.h
			            };
						items.push(item);

						if( i == retour.items.length-1 ){
							gallery = new PhotoSwipe( $(".pswp")[0], PhotoSwipeUI_Default, items, options);
							gallery.init();

							gallery.listen('imageLoadComplete', function(index, item) {
								if( index % 10 == 0 && index > 1 ){
									$(".gallery-btn:eq(0)").trigger('click');
								}
							});

							return false
						}

					});
				}
			});

		
		}else{

			$('li.photoswipe', $tiles).each(function() {
				item = {
					src: $(this).attr('data-src'),
					w:   $(this).attr('data-width'),
					h:   $(this).attr('data-height')
	            };
				items.push(item);
			});

			gallery = new PhotoSwipe( $(".pswp")[0], PhotoSwipeUI_Default, items, options);
			gallery.init();

		}

	}

	function applyLayout() {

		var loadedImages = 0;
		var imgLoad      = imagesLoaded( $tiles );

		// $tiles.imagesLoaded(function() {
		imgLoad.on("done", function (instance) {

			$('.progress-bar').css('width', '0%');
			$('.progress-bar-text').html('0%');

			$handler = $('li', $tiles);
			$handler.wookmark(options);
			
			$handler.removeClass('wookmark-inactive');

			$(".spinner").hide();
			$("li.photoswipe a", $tiles).click(function(e){
				e.preventDefault();
			});

			/**
			 * Photoswipe
			 */
			$('li.photoswipe', $tiles).unbind('click').click(openPhotoSwipe);

			if( $handler.length >= $tiles.data('max') ){
				$(".gallery-btn").hide();
			}

		}).on("progress", function (instance, image) {

			// Update progress bar after each image has loaded and remove loading state
			percentage = (++loadedImages / $('li', $tiles).length * 100);
			$('.progress-bar').css('width', Math.ceil(percentage) + '%');
			$('.progress-bar-text').html(Math.ceil(percentage) + '%');

		});


	}

	applyLayout();

	$(".gallery-btn").click(loadPhotos);

})(jQuery);