/*!	project: gmaps plugin
	author:  @mcube l'agence web
	date:    2014-11-19,
	version: 2.2
*/

/*
Releases :
	v 2.2 :
		- intégration de Gmaps.js dans la version min
		- possibilité de spécifier une icone par défaut
		- possibilité de spécifier une icone par marker
*/

/*
Exemple :
$("#map").gmapsPlugin({
	lat:          45.763764,
	lng:          4.835035,
	zoom:         10,
	markers: [
		{
			"lat":     43.949405, 
			"lng":     4.806473,
			"content": "<h6 class='color-residence'>Avignon (84)</h6>",
			"icon":    "/img/pointer@2x.png"
		}
	],
	overlay: {
		on : 'hover',
		verticalOffset: -10
	}
});
*/

(function ($) {

    $.fn.gmapsPlugin = function(options) {

		/* Paramètres par défaut */
		var defaults = {

			lat:          45.763764, /* coordonnées centrage de la map */
			lng:          4.835035,  /* defaut Lyon */
			zoom:         10,        /* zoom par défaut */
			markerImage:  false,     /* icon par défaut */
			markerWidth:  60,        /* largeur icon par défaut not @2x */
			markerHeight: 60,        /* hauteur icon par défaut not @2x */
			autoCenter:   true,      /* centrage auto on resize */
            styles : 	  false,     /* styles custom de la map */
            markers : 	  false,     /* tableau de markers */
            overlay: 	  false      /* action marker */

		};

		var param           = $.extend(defaults, options);
		var resizeTimerMaps = null;
		var markers         = [];
		var myIcon          = '';

		/* Charge l'icone par défaut si renseignée */
		if( param.markerImage ){
			myIcon = new google.maps.MarkerImage( param.markerImage, null, null, null, new google.maps.Size(param.markerWidth,param.markerHeight));
		}

		/* Test si Modernizr est chargé et si on veut un évènement au hover du marker */
		if ( (!window.Modernizr) && (param.overlay.on == 'hover')){
			alert("Oh, damn! Vous n'avez pas installer Modernizr et vous voulez afficher les infos au hover...!");
		}

		/* Boucle sur le tableau de marqueur */
		this.each(function(){

			var $this = $(this);

		    map = new GMaps({
		        div:            $this.attr('id'),
		        lat:            param.lat,
		        lng:            param.lng,
		        zoom:           param.zoom,
		        scrollwheel:    false,
		        zoomControl:    true,
		        zoomControlOpt: {
		            style:    'SMALL',
		            position: 'TOP_LEFT'
		        },
		        panControl : false,
		        options: {
		            'styles' : param.styles
		        }
		    });

			/* si aucun marker on prend le point par défaut */
			if( !param.markers ){

				/* Charge l'icone spécifique au marqueur si celle-ci est renseignée */
	    		if ( param.markerImage !== false ){
					myIcon = new google.maps.MarkerImage( param.markerImage, null, null, null, new google.maps.Size(param.markerWidth,param.markerHeight));
	    		}else{
					myIcon = new google.maps.MarkerImage( 'http://maps.google.com/mapfiles/marker.png');
	    		}

				/* Ajout du marqueur */
	    		var myMarker = map.addMarker({
			        lat:  param.lat,
			        lng: param.lng,
			        index : 0,
			        icon: myIcon,
			        // content: value.content,
			        click: function(e){
			        	if( param.overlay.on == 'click' ) loadOverlay( 0 );
			        },
			        mouseover: function(e){
			        	if( param.overlay.on == 'hover' ) loadOverlay( 0 );
			        }
			    });
			    markers.push(myMarker);
			
			}else{

		    	$.each(param.markers,function(key,value){

		    		/* Charge l'icone spécifique au marqueur si celle-ci est renseignée */
		    		if ( typeof(value.icon) != "undefined" ){
						myIcon = new google.maps.MarkerImage( value.icon, null, null, null, new google.maps.Size(param.markerWidth,param.markerHeight));
		    		}

		    		/* Ajout du marqueur */
		    		var myMarker = map.addMarker({
				        lat:  value.lat,
				        lng: value.lng,
				        index : key,
				        icon: myIcon,
				        content: value.content,
				        click: function(e){
				        	if( param.overlay.on == 'click' ) loadOverlay( key );
				        },
				        mouseover: function(e){
				        	if( param.overlay.on == 'hover' ) loadOverlay( key );
				        }
				    });
				    /* Ajout du marqueur dans le tableau de marqueurs */
				    markers.push(myMarker);	
		    	});

		    }

	    	/* Recentre la map au resize */
		    if( param.autoCenter ){
			    $(window).resize(function(){
			        if (resizeTimerMaps) clearTimeout(resizeTimerMaps);
			        resizeTimerMaps = setTimeout(centerMap, 100);
			    });
			}

		});

		/* Centrage de la map */
		var centerMap = function(){
		    map.setCenter( param.lat, param.lng );
		}

		/* Bulle marqueur */
		var loadOverlay = function(index){

			/* Défini la variable isTouch si pas défini auparavant */
			if( typeof isTouch === "undefined" ) isTouch = Modernizr.touch;

			/* Force le click sur tablette, smartphone, ... */
			if( isTouch ){ param.overlay.on = 'click'; }

		    marker         = markers[index];
		    markerPosition = marker.getPosition();
		    markerLat      = markerPosition.lat();
		    markerLng      = markerPosition.lng();

            /* Action au click */
		    if( param.overlay.on == "click" ){
			    map.setCenter(markerLat, markerLng); // center map on marker		    
			    if( param.overlay.zoom ) map.setZoom( param.overlay.zoom ); // zoom on marker
			}
		    
		    /* Contruit le contenu de la bulle */
		    contenu = '<div class="map-overlay"><div class="map-overlay-content">';
		    /* Ajoute la bouton pour fermer la bulle */
		    if( param.overlay.on == 'click' ) contenu += '<a href="#" class="map-overlay-close"><span>&times;</span></a>';
		    contenu += marker.content;
		    contenu += '</div></div>';

		    /* Efface toutes les bulles */
		    map.removeOverlays();

		    /* Positionne la bulle */
		    overlayVA = 'top';
		    overlayHA = 'center';
		    overlayVO = 0;
		    overlayHO = 0;

	    	if( param.overlay.verticalAlign ) 
	    		overlayVA = param.overlay.verticalAlign;
	    	if( param.overlay.horizontalAlign ) 
	    		overlayHA = param.overlay.horizontalAlign;
	    	if( param.overlay.verticalOffset ) 
	    		overlayVO = param.overlay.verticalOffset;
	    	if( param.overlay.horizontalOffset ) 
	    		overlayHO = param.overlay.horizontalOffset;

	    	/* Affiche la bulle */
		    map.drawOverlay({
		        lat:              markerLat,
		        lng:              markerLng,
		        verticalAlign:    overlayVA,
		        horizontalAlign:  overlayHA,
		        verticalOffset:   overlayVO,
		        horizontalOffset: overlayHO,
		        layer:            'floatPane',
		        content:          contenu
		    });

		    /* Active l'action lors du click sur la fermeture de la bulle */
		    if( param.overlay.on == "click" ){
			    $(document).on('click touchstart', '.map-overlay-close', function(e) {
			        e.preventDefault();
			        map.removeOverlays();
			        if( param.overlay.zoomOut ) map.setZoom( param.zoom );
			    });
			}

			/* Ferme la bulle si affichage au survol */
			if( param.overlay.on == "hover" ){
				google.maps.event.addListener(marker, 'mouseout', function() {
					map.removeOverlays();
				});
			}

		}

    };

})(jQuery);