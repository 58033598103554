/*
 * Copyright (C) 2021 mcube (contact@mcube.fr)
 */

var mcubeRgpdArgs = {
	'ajaxurl': 'cookie/fr.html',
	'tech':  {
		'header': '',
		'footer': '',
		'mandatory': true,
	},
	'perf':  {
		'header': '',
		'footer': "<!-- Google Tag Manager -->\n" +
			"<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
			"new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
			"j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
			"'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
			"})(window,document,'script','dataLayer','GTM-5N8DCK6');</script>\n" +
			"<!-- End Google Tag Manager -->",
		'mandatory': false,
	}
};
var cookieValue = 'done:0!all:0!';
var update = [];
var cookieList = ["tech", "perf"];
var injectedScripts = [];
var previousScrollBar;

jQuery(function () {
	initDefautCookieConsent();
	initCookieConsent();
});

function initCookieConsent() {
	setUpdateCookies(false);

	// Choix déjà effectué
	if (getCookieByType('done')) {
		cookieValue = jQuery.cookie('cookie_consent');
		addHeaderAndFooterScripts();
	} else {
		buildAndShowModal();
	}

	// Lien pour ouvrir la modale
	jQuery('.open-rgpd-settings').on('click', function (e) {
		e.preventDefault();
		buildAndShowModal();
	});
}

function initRgpdModal() {

	// Settings checkboxes
	jQuery('#cookie-modal-settings input[type="checkbox"]').on('click', function (e) {
		var checked = jQuery(this)[0].checked ? 1 : 0;
		var accepted = getCookieByType(jQuery(this).attr('id'));
		update[jQuery(this).attr('id')] = (checked !== accepted);
	});

	// Settings accept button
	jQuery('#cookie-modal-settings .cc-btn-accept').on('click', function (e) {
		e.preventDefault();
		cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
		setCookieValues();
	});

	// Main modal accept button
	jQuery('#cookie-modal-main .cc-btn-accept').on('click', function (e) {
		e.preventDefault();
		cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
		setUpdateCookies(true);
		setCookieValues();
	});

	// Main modal decline button
	jQuery('#cookie-modal-main .cc-btn-decline').on('click', function (e) {
		e.preventDefault();
		cookieValue = 'done:1!all:0!tech:1!perf:0!perso:0!social:0';
		setUpdateCookies(true);
		setCookieValues();
	});

	// Settings OK button
	jQuery('#cookie-modal-settings .cc-btn-done').on('click', function (e) {
		e.preventDefault();
		cookieValue = 'done:1!all:0';
		setUpdateCookies(true);
		jQuery('input[name="cc-cookie"]').each(function (index) {
			cookieValue += '!' + jQuery(this).val() + ':' + (0 + jQuery(this).is(':checked'));
		});
		setCookieValues();
	});

	// Show settings
	jQuery('#cookie-modal-main .cc-btn-param').on('click', function (e) {
		e.preventDefault();
		jQuery('#cookie-modal-main').hide();
		jQuery('#cookie-modal-settings').css('display', 'flex');
		smoothScrollResizeRGPD();
	});

	// Return to main screen
	jQuery('#cookie-modal-settings .cc-btn-return').on('click', function (e) {
		e.preventDefault();
		jQuery('#cookie-modal-main').css('display', 'flex');
		jQuery('#cookie-modal-settings').hide();
		smoothScrollResizeRGPD();
	});

}

function setUpdateCookies(toUpdate) {
	for (var i = 0; i < cookieList.length; i++) {
		update[cookieList[i]] = toUpdate;
	}
}

function buildAndShowModal() {
	// Show modal
	if (jQuery('#cookie-modal-main').length) {
		showCookieModal();
	} else {
		// Build modal
		jQuery.ajax({
			type: "GET",
			url: mcubeRgpdArgs.ajaxurl,
			data: {action: 'mcube_rgpd_cookiebar'},
			success: function (response) {
				jQuery('body').append(response);
				initRgpdModal();
				initParametersPage();
				showCookieModal();
				smoothScrollResizeRGPD();
				window.addEventListener('resize', smoothScrollResizeRGPD);
			}
		});
	}
}

// Dirty code : créé une smooth scrollbar et force une hauteur
// sur le body inner pour toujours garder les boutons à l'écran
function smoothScrollResizeRGPD() {
	try{
		if (scrollBar) {
			let modalBody = jQuery('.cc-modal:visible').find('.cc-body')[0];
			if (modalBody) {
				if (previousScrollBar) {
					previousScrollBar.destroy();
				}
				let modalBodyInner = modalBody.querySelector('.cc-body-inner');
				modalBodyInner.style.height = 'auto';
				previousScrollBar = Scrollbar.init(modalBodyInner);
				modalBodyInner.style.height = modalBody.getBoundingClientRect().height + 'px';
				previousScrollBar.update();
			}
		}
	}
	catch (e){}
}

function initParametersPage(showParamsPage) {

	if (jQuery.cookie('cookie_consent')) {
		cookieValue = jQuery.cookie('cookie_consent');
	}

	cookieList.forEach(function (cookie) {
		checked = getCookieByType(cookie) ? true : false;
		jQuery("input[name='cc-cookie'][value='" + cookie + "']").attr('checked', checked);
	});

	// S’il n'y a pas de cookie, on recupère les valeurs par défaut réglé en BO
	if (jQuery.cookie('cookie_consent')) {
		jQuery('.cc-type').each(function () {
			if (jQuery(this).data('default') == '2') {
				jQuery(this).find('input[type="checkbox"]').prop('disabled', true);
			}
		});
	} else {
		jQuery('.cc-type').each(function () {
			cookieValue += '!' + jQuery(this).attr('data-type') + ':' + jQuery(this).attr('data-default');

			// Obligatoire
			if (jQuery(this).data('default') == '2') {
				jQuery(this).find('input[type="checkbox"]').prop('disabled', true);
			}

			// Vrai ou Obligatoire
			if (jQuery(this).data('default') == '1' || jQuery(this).data('default') == '2') {
				jQuery(this).find('input[type="checkbox"]').prop('checked', true);
			}
		});
	}
}

function setCookieValues() {

	var doUpdate = false;
	var done = getCookieByType('done'); // est-ce que la validation a ete faite

	// On regarde si on doit mettre à jour le cookie
	for (i = 0; i < cookieList.length; i++) {
		if (update[cookieList[i]] || !done) {
			doUpdate = true;
			break;
		}
	}

	// Maj du cookie
	if (doUpdate) {
		jQuery.cookie('cookie_consent', cookieValue, {
			expires: jQuery('#cookie-modal-settings').data('expiration'), path: '/'
		});

		// On injecte le code si maj cookie
		for (i = 0; i < cookieList.length; i++) {
			if (update[cookieList[i]] || !done) {
				if (getCookieByType(cookieList[i])) {
					jQuery('head').append(mcubeRgpdArgs[cookieList[i] + '_header']);
					jQuery('body').append(mcubeRgpdArgs[cookieList[i] + '_footer']);
				}
			}
		}
	}
	initParametersPage();
	hideCookieModal();
	addHeaderAndFooterScripts();
}

// Ouverture de la modale
function showCookieModal() {
	if (jQuery('#cc-mc-rgpd').hasClass('blocking')) {
		document.body.classList.add('no-scroll');
	}
	jQuery('#cookie-modal-main').fadeIn();
	jQuery('#cc-mc-rgpd').addClass('show');
}

// Fermeture de la modale
function hideCookieModal() {
	jQuery('#cookie-modal-main').fadeOut();
	jQuery('#cc-mc-rgpd').removeClass('show');
	document.body.classList.remove('no-scroll');
}

// Lecture du cookie
function getCookieByType(type) {
	if (jQuery.cookie('cookie_consent')) {
		cookies = jQuery.cookie('cookie_consent').split('!');
		for (var i = 0; i < cookies.length; i++) {
			cookie = cookies[i].split(':');
			if (cookie[0] === type) {
				return parseInt(cookie[1]);
			}
		}
	}
	return 0;
}

function addHeaderAndFooterScripts() {
	cookieList.forEach(function (cookieType) {
		if (mcubeRgpdArgs[cookieType] && getCookieByType(cookieType) && !injectedScripts[cookieType]) {
			jQuery('body').append(mcubeRgpdArgs[cookieType].footer);
			jQuery('head').append(mcubeRgpdArgs[cookieType].header);
			injectedScripts[cookieType] = true;
		}
	});
}

function initDefautCookieConsent() {
	cookieList.forEach(function (cookieType) {
		if (mcubeRgpdArgs[cookieType] && mcubeRgpdArgs[cookieType].mandatory) {
			jQuery('body').append(mcubeRgpdArgs[cookieType].footer);
			jQuery('head').append(mcubeRgpdArgs[cookieType].header);
			injectedScripts[cookieType] = true;
		}
	});
}
